<template>
  <base-section id="security">
    <base-section-heading title="Security">
    </base-section-heading>
    <v-responsive class="mx-auto" max-width="1350">
      <v-container fluid>
        <v-row>
          <v-col style="padding: 16px;">
<p>Tentifly OÜ takes the security of your data seriously. In addition to privacy policies and related processes, we implement additional procedural and technical practices to protect you and your data. As transparency is one of our core principles, we aim to be as clear and open as we can about the way we handle security and your data. Terms used and not defined on this page have the meaning set forth in the Terms of Service and Privacy Policy.</p>
<h1>Confidentiality</h1>
<p>We have strict controls in place and are committed to ensuring that Customer Data is not accessed by any party without Customer consent. The operation of the Services requires that some employees have access to Customer Data. Such employees are prohibited from accessing Customer Data unless it is necessary to do so and only by your permission.</p>
<h1>Personnel</h1>
<p>Our employees receive privacy and security training during onboarding as well as on an ongoing basis. Both third-party contractors and our employees are contractually bound to our policies regarding Customer Data.</p>
<h1>Compliance</h1>
<p>Customer Data is accessed via secure protocols such as HTTPS and SSH. Additionally, all passwords are encrypted on our servers and databases. All software is updated regularly to ensure the latest security patches.</p>
<h1>Security Features</h1>
<p>We provide Workspace owners with additional tools to enable their own users to protect and restrict access to their Customer Data. If that is not enough for you, Tentifly OÜ can provide additional and customized solutions upon Customer's request to suit their needs.</p>
<h1>Sign In</h1>
<p>All login operations to Services are only possible via secure methods. The access and privileges to Services can be configured by the Workspace owner or personnel authorized by the owner. Upon successful login the user is provided with an authorization token which allows access to the part of the Services accessible by configured privileges. The token is valid only temporarily and after its expiration the user must re-login to acquire a new and valid authorization.</p>
<h1>Data Retention</h1>
<p>Available aretools which will allow the privileged user to manage other users and the data of the customers. Data inserted into Services belongs to you. Therefore, no restrictions are set to data management.</p>
<h1>Deletion and return of Customer Data</h1>
<p>We provide an option to get Customer Data destroyed and/or returned after the end of subscription. This includes Customer Data in Services and our backups.</p>
<h1>Data Encryption</h1>
<p>The Services utilize the latest recommended security protocols and encryption to protect your data at transit and at rest. During transit your data is encrypted using SSL technology, which makes it impossible for third-party actors to steal and read any of the information shared between our Services and you. We upgrade promptly the Services to respond to new security weaknesses and implement better practices as they evolve.</p>
<h1>Disaster Recovery</h1>
<p>Upon major disaster, our team can recover your data up to one week. We use only such hosting providers that adhere to the same transparent standards listed in the SLA in your Customer Agreement which can differ by Customer, but are always at the industry standards on which you can rely on.</p>
<h1>Availability</h1>
<p>All our Services are available 24/7 for any usage under Services and we are committed to high availability.</p>
<h1>Host Management</h1>
<p>We choose our hosting partners carefully and have processes in place to occasionally validate the suitability of a partner by evaluating carefully aspects such as security policies, features and measures, services availability and SLA conditions, their HQ and provided server locations, service performance, data encryption and recovery features and many more. We require all our hosting partners to be fault tolerant, for failures of individual servers or entire data centers and adhere to the recommended industry standards.</p>
<h1>Incident Management</h1>
<p>Tentifly OÜ will notify you of any unauthorized access to your Customer Data upon discovery of any security breaches. Tentifly OÜ has incident management guidelines and procedures in place to handle such events.</p>
<h1>Product Security Practices</h1>
<p>New features, functionality, and design changes go through a review and audit process by our development team. The products we provide are designed to be secure, hence no data is provided by Services before proper authorization. The data fetched in our standard Services will never remain on the user's device. We perform occasionally additional checks to detect any additional missed security leaks.</p>
          </v-col>
        </v-row>
      </v-container>
    </v-responsive>
  </base-section>
</template>

<script>
export default {
  name: "Security",

  data: () => ({
    
  })
};
</script>
